//relate to all pages
import {useLoading} from "vue-loading-overlay";
import axios from "axios";
import * as Sentry from "@sentry/vue";

export function SentryInit (app) {
    Sentry.init({
        app,
        dsn: document.querySelector('meta[name="sentry-dns"]').content,
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 1.0,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            Sentry.replayIntegration({
                // Additional SDK configuration goes in here, for example:
                maskAllText: false,
                blockAllMedia: true,
                networkDetailAllowUrls: [/api\/v1\/*/],
                networkRequestHeaders: ['X-Custom-Header'],
                networkResponseHeaders: ['X-Custom-Header'],
            }),
        ],
    });
}

export function findGetParameter(parameterName) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    for (const key of urlParams.keys()) {
        if (parameterName.toLowerCase() === key.toLowerCase()) {
            let value = urlParams.get(key);
            value = value.replace(/ /g, '+');
            try {
                return decodeURIComponent(value);
            } catch (e) {
                console.error(e);
                return null;
            }
        }
    }
    return null;
}

export async function checkPhone(home_phone, first_name) {
    let useLoader = useLoading();
    let loader = useLoader.show({
        container: this.$refs.formContainer
    });

    await axios
        .post(import.meta.env.VITE_API_CHECK_PHONE, {
            home_phone: home_phone,
            first_name: first_name,
        })
        .then(async (response) => {
            if (response.data.status === 'not found') {
                globalParams.requested_amount = null;
                loader.hide();
            } else {
                loader.hide();
                globalParams.requested_amount = this.fields.requested_amount;
                globalParams.email = response.data.customerDataReapply.email;
                window.location.href = '/reapply?email=' + response.data.customerDataReapply.email + (globalParams.click_id === null ? '' : '&click_id=' + globalParams.click_id);
            }
        })
        .catch((error) => {
            loader.hide();
        });
}

export function saveError(e) {
    if (e.message === "Network Error") {
        setTimeout(() => {
            this.$toast.error("Internet connection lost. Please, do not refresh the page.")
        }, 200);
    }
    const error = {
        'message': e.message,
        'stack': e.stack,
        'url': window.location.href
    };
    axios.post(import.meta.env.VITE_API_SAVE_ERROR, error).catch((error) => {
    });
}

export function saveVariable(savingVariable) {
    const variable = {
        'message': JSON.stringify(savingVariable),
        'stack': 'variable',
        'url': window.location.href
    };
    axios.post(import.meta.env.VITE_API_SAVE_ERROR, variable).catch((error) => {
    });
}

export async function handleGetParams(vue) {
    const uid = findGetParameter('uid');
    const offer = findGetParameter('offer');
    const utm_source = findGetParameter('utm_source');
    const utm_medium = findGetParameter('utm_medium');

    let paramSubid2 = findGetParameter('subid2');
    let paramEmail = findGetParameter('email');

    if (paramSubid2 !== null) {
        globalParams.email = paramSubid2;
    } else if (paramEmail !== null) {
        globalParams.email = paramEmail;
    }
    globalParams.aid = null;
    globalParams.aid_verified = false;

    globalParams.aid = findGetParameter('aid');
    globalParams.pub_id = findGetParameter('pub_id');


    if (globalParams.pub_id !== null && globalParams.aid === null) {
        globalParams.aid = globalParams.pub_id;
    }

    globalParams.first_name = findGetParameter('first_name');
    globalParams.home_phone = findGetParameter('home_phone');

    globalParams.aid_verified = findGetParameter('aid_verified');
    if (globalParams.aid_verified === 1 || globalParams.aid_verified === '1') {
        globalParams.aid_verified = true;
    }

    if (offer !== null && uid !== null) {
        let offer_params = {
            "uid": uid,
            "offer": offer,
            "utm_source": utm_source,
            "utm_medium": utm_medium,
        };

        if (globalParams.email !== null) {
            offer_params.subid2 = globalParams.email
        }

        let cParam = findGetParameter('c');
        if (cParam !== null) {
            offer_params.c = cParam;
        }

        globalParams.offer_params = JSON.stringify(offer_params);
        await axios.post(import.meta.env.VITE_API_GET_CLICK_ID, offer_params, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            globalParams.click_id = response.data.click_id;
            vue.fields.click_id = response.data.click_id;
            if (!vue.$cookies.get('click_id_initialized')) {
                vue.$cookies.set('click_id_initialized', true, 60 * 60);
                vue.$cookies.set('click_id', response.data.click_id, 60 * 60);
                //vue.$cookies.set('share_cookies', 1, 600);
                //window.location.reload()
            }
        }).catch((error) => {
            console.log('handleGetParamsError: ', error);
        });
    }

    const urlParams = new URLSearchParams(window.location.search);
    const entries = urlParams.entries();
    let sub_ids = {};
    for (const entry of entries) {
        if (entry[0].toLowerCase() === 'subid' || entry[0].toLowerCase() === 'subid2' ||
            entry[0].toLowerCase() === 'subid3' || entry[0].toLowerCase() === 'subid4' ||
            entry[0].toLowerCase() === 'subid5'
        ) {

            if (/^[a-z]+$/.test(entry[0])) { //check is not camelCased
                let base = entry[0].substring(0, 3);  // "sub"
                let rest = entry[0].substring(3);     // rest of string
                // change first letter + rest string starting from second char
                rest = rest.charAt(0).toUpperCase() + rest.slice(1);
                entry[0] = base + rest;
            }
            sub_ids[entry[0]] = entry[1];
        }

        if (sub_ids['subId'] && utm_source) {
            // Check if subId does not already contain utm_source
            if (!sub_ids['subId'].includes(utm_source)) {
                sub_ids['subId'] = `${sub_ids['subId']}-${utm_source}`;
            }
        }
    }

    if (JSON.stringify(sub_ids) !== '{}') {
        globalParams.sub_ids = sub_ids;
    }

    globalParams.flow_id = document.querySelector('meta[name="flow-id"]').content;

    if (globalParams.click_id === null) {
        let clickIdFromUrl = findGetParameter('clickId');
        if (clickIdFromUrl === null) {
            clickIdFromUrl = findGetParameter('click_id')
        }
        globalParams.click_id = clickIdFromUrl;
    }
}

export async function countVisitor(cookies, shortSyntax = false) {

    let cookiesVariable = shortSyntax ? cookies : cookies.$cookies;
    let click_id_from_url;
    click_id_from_url = findGetParameter('clickId');
    let click_id_changed = false;
    if (click_id_from_url != null && click_id_from_url !== globalParams.click_id) {

        cookiesVariable.set('click_id', globalParams.click_id, 60 * 60 * 24 * 90);
        click_id_changed = true;
    }

    if (!cookiesVariable.isKey('visit') || click_id_changed) {
        if (globalParams.email === null) {
            cookiesVariable.set('visit', true, 60 * 60);
            try {
                const response = await axios.get(import.meta.env.VITE_API_COUNT_VISITOR, {
                    params: {
                        'click_id': globalParams.click_id,
                        'sub_ids': JSON.stringify(globalParams.sub_ids),
                        'click': 0,
                        'ref_url': document.referrer,
                        'flow_id': globalParams.flow_id,
                        'cookie_mark': 0,
                        'current_url': window.location.href
                    }
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                cookiesVariable.set('visit_id', response.data, 60 * 60);
            } catch (e) {
                console.error(e)
            }
        }
    }
}

export async function countClick(vue, fromReapply = 0) {
    const click_id = findGetParameter('clickId');
    let click_id_changed = false;
    if (click_id != null && click_id !== vue.$cookies.get('click_id')) {
        vue.$cookies.set('click_id', click_id, 60 * 60 * 24 * 90);
        click_id_changed = true;
        vue.$cookies.remove('visit')
    }

    if (!vue.$cookies.isKey('click') || click_id_changed) {
        if ((vue.$cookies.isKey('email') && fromReapply) || (!fromReapply && !vue.$cookies.isKey('email'))) {
            vue.$cookies.set('click', true, 60 * 60);
            if (!vue.$cookies.isKey('visit')) {
                vue.$cookies.set('visit', true, 60 * 60);
                if (fromReapply) {
                    vue.$cookies.set('cookie_mark', true, 60 * 60);
                }
                try {
                    await axios.get(import.meta.env.VITE_API_COUNT_VISITOR, {
                        params: {
                            'click_id': globalParams.click_id,
                            'sub_ids': JSON.stringify(globalParams.sub_ids),
                            'click': 1,
                            'ref_url': document.referrer,
                            'flow_id': globalParams.flow_id,
                            'cookie_mark': fromReapply,
                            'current_url': window.location.href
                        }
                    }, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then((response) => {
                        vue.$cookies.set('visit_id', response.data, 60 * 60);
                    });
                } catch (e) {
                    vue.$toast.error('Internet connection is failed')
                }
            } else {
                try {
                    await axios.get(import.meta.env.VITE_API_COUNT_CLICK, {
                        params: {
                            'visit_id': vue.$cookies.get('visit_id'),
                        }
                    }, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });

                } catch (e) {
                    vue.$toast.error('Internet connection is failed')
                }
            }
        }
    }
}

export async function sendVisitorActionTypeRequest(vue) {
    if (vue.$cookies.isKey('visit_id')) {
        await axios.post(import.meta.env.VITE_API_ACTION_TYPE_VISITOR, {
            'visitor_id': vue.$cookies.get('visit_id'),
            'action_type': vue.action_type,
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).catch((e) => {
            return vue.$toast.error("Internet connection lost. ");
        });
    }
}

export function phoneMask(field) {
    if (this.fields[field] != null) {
        let number = this.fields[field].replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.fields[field] = number[1];
        if (number[2].length > 0) {
            this.fields[field] = number[1] + '-' + number[2];
            if (number[3].length > 0) {
                this.fields[field] += '-' + number[3];
            }
        }
    }
}

export async function triggerReapplyForm(vue, loader) {
    if (loader) {
        loader.hide()
    }
    if (globalParams.email !== null) {
        vue.fields.email = globalParams.email;
        await vue.checkEmail(true);
    }

    /*if (globalParams.aid_verified) {
        await vue.submitWithPubId();
    }*/

    if (globalParams.home_phone !== null && globalParams.first_name !== null && globalParams.email === null) {
        await vue.checkPhone(globalParams.home_phone, globalParams.first_name);
    }
}

export function removeRefillData() {
    this.$cookies.remove('fieldsGetStarted');
    this.$cookies.remove('customerDataMain');
    this.$cookies.remove('customerState');
}

function updateCookiesAndGlobalParams(vue, deviceId, data) {
    let cookie_name = globalParams.cookie_device_id_name ?? 'x3GhSd9234tewq34'
    vue.$cookies.set(cookie_name, deviceId);
    vue.$cookies.set('email', data.email);
    vue.$cookies.set('risk_finger_print', data.risk_finger_print ?? data.fraud_chance);
    vue.$cookies.set('risk_processed_finger_print', data.risk_processed_finger_print ?? 1);
    vue.$cookies.set('is_crawler', data.is_crawler);

    globalParams.device_id = deviceId;
    globalParams.email = data.email ?? globalParams.email;
    globalParams.risk_finger_print = data.fraud_chance ?? data.risk_finger_print;
    globalParams.risk_processed_finger_print = data.risk_processed_finger_print ?? 1;
    globalParams.is_crawler = data.is_crawler;
}
export async function getRiskAndRiskProcessed(vue) {
    await axios
        .get(import.meta.env.VITE_API_GET_RISK_AND_RISK_PROCESSED)
        .then(async (response) => {
            if (response.data && response.data.ip_check !== undefined) {
                vue.fields.risk_processed = response.data.ip_check.risk_processed;
                vue.fields.risk = response.data.ip_check.risk;
                globalParams.risk = response.data.ip_check.risk
                globalParams.risk_processed = response.data.ip_check.risk_processed
                vue.$cookies.set('risk', response.data.ip_check.risk)
                vue.$cookies.set('risk_processed', response.data.ip_check.risk_processed)
            }
        });
}

export async function handleGetCookies(vue) {
    if(window.isSharedCookie === 1){
        const params = new URLSearchParams(window.location.search);
        if (params.get('aid')) {
            return;
        }
        try {
            let deviceId = globalParams.device_id ?? vue.$cookies.get(globalParams.cookie_device_id_name) ?? vue.$cookies.get('x3GhSd9234tewq34');
            if (deviceId !== null) {
                const getResponse = await axios.post(
                    import.meta.env.VITE_URL_GET_FINGERPRINT_COOKIES,
                    { device_id: deviceId },
                    { headers: { 'Content-Type': 'application/json' } }
                );

                if (getResponse.data.message !== 'No data found') {
                    updateCookiesAndGlobalParams(vue, deviceId, getResponse.data);
                    axios
                        .post(
                            import.meta.env.VITE_URL_LOG_IPQS,
                            {
                                sharing_device_id : window.isSharedCookie,
                                device_id_in_cookie  : true,
                                device_id : deviceId,
                                found_in_redis : true,
                                trigger_ipqs : false,
                                request_duration : null,
                                started_request : null,
                                stop_request : null,
                                risk : null,
                                risk_processed : null,
                                risk_finger_print :  globalParams.risk_finger_print ?? vue.$cookies.get(globalParams.risk_finger_print),
                                is_crawler :  globalParams.is_crawler ?? vue.$cookies.get(globalParams.is_crawler),
                                risk_processed_finger_print: globalParams.risk_processed_finger_print ?? vue.$cookies.get(globalParams.risk_processed_finger_print),
                            },
                            { headers: { 'Content-Type': 'application/json' } }
                        )
                    return;
                }

                if (deviceId && globalParams.email !== null) {
                    const retryResponse = await axios.post(
                        import.meta.env.VITE_URL_SET_FINGERPRINT_COOKIES,
                        {
                            device_id: deviceId,
                            email: globalParams.email ?? vue.$cookies.get('email'),
                            risk_finger_print: vue.$cookies.get('risk_finger_print'),
                            risk_processed_finger_print: vue.$cookies.get('risk_processed_finger_print'),
                            is_crawler: vue.$cookies.get('is_crawler'),
                        },
                        { headers: { 'Content-Type': 'application/json' } }
                    );

                    if (retryResponse.data.message !== 'No data found') {
                        updateCookiesAndGlobalParams(vue, deviceId, retryResponse.data);
                    }
                    return;
                }
            }

            return new Promise((resolve, reject) => {
                const onFingerprintFinished = (e) => {
                    const result = e.detail;
                    globalParams.end_ipqs_request = Date.now();
                    globalParams.duration_ipqs_requst =(globalParams.end_ipqs_request - globalParams.started_ipqs_request) / 1000;
                    globalParams.device_id = result.device_id
                    document.removeEventListener('fingerPrintFinished', onFingerprintFinished);
                    axios
                        .post(
                            import.meta.env.VITE_URL_GET_FINGERPRINT_COOKIES,
                            { device_id: result.device_id },
                            { headers: { 'Content-Type': 'application/json' } }
                        )
                        .then((resp) => {
                            if (resp.data.message !== 'No data found') {
                                updateCookiesAndGlobalParams(vue, result.device_id, {
                                    email: resp.data.email ?? globalParams.email,
                                    risk_finger_print: result.fraud_chance,
                                    risk_processed_finger_print: 1,
                                    is_crawler: result.is_crawler,
                                });
                                axios
                                    .post(
                                        import.meta.env.VITE_URL_LOG_IPQS,
                                        {
                                            sharing_device_id : window.isSharedCookie,
                                            device_id_in_cookie  : false,
                                            device_id : result.device_id,
                                            found_in_redis : true,
                                            trigger_ipqs : true,
                                            request_duration : globalParams.duration_ipqs_requst,
                                            started_request : new Date(globalParams.started_ipqs_request).toISOString().slice(0, 23).replace('T', ' '),
                                            stop_request : new Date(globalParams.end_ipqs_request).toISOString().slice(0, 23).replace('T', ' '),
                                            risk : null,
                                            risk_processed : null,
                                            risk_finger_print : result.fraud_chance,
                                            is_crawler : result.is_crawler,
                                            risk_processed_finger_print: 1,
                                        },
                                        { headers: { 'Content-Type': 'application/json' } }
                                    )
                            } else {
                                axios
                                    .post(
                                        import.meta.env.VITE_URL_SET_FINGERPRINT_COOKIES,
                                        {
                                            device_id: result.device_id,
                                            email: globalParams.email,
                                            risk_finger_print: result.fraud_chance,
                                            risk_processed_finger_print: 1,
                                            is_crawler: result.is_crawler,
                                        },
                                        { headers: { 'Content-Type': 'application/json' } }
                                    )
                                    .then((setResp) => {
                                        if (setResp.status === 200) {
                                            updateCookiesAndGlobalParams(vue, result.device_id, {
                                                email: globalParams.email,
                                                risk_finger_print: result.fraud_chance,
                                                risk_processed_finger_print: 1,
                                                is_crawler: result.is_crawler,
                                            });
                                        }
                                    });
                                axios
                                    .post(
                                        import.meta.env.VITE_URL_LOG_IPQS,
                                        {
                                            sharing_device_id : window.isSharedCookie,
                                            device_id_in_cookie  : false,
                                            device_id : result.device_id,
                                            found_in_redis : false,
                                            trigger_ipqs : true,
                                            request_duration : globalParams.duration_ipqs_requst,
                                            started_request : new Date(globalParams.started_ipqs_request).toISOString().slice(0, 23).replace('T', ' '),
                                            stop_request : new Date(globalParams.end_ipqs_request).toISOString().slice(0, 23).replace('T', ' '),
                                            risk : null,
                                            risk_processed : null,
                                            risk_finger_print : result.fraud_chance,
                                            is_crawler : result.is_crawler,
                                            risk_processed_finger_print: 1,
                                        },
                                        { headers: { 'Content-Type': 'application/json' } }
                                    )
                            }
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                };

                document.addEventListener('fingerPrintFinished', onFingerprintFinished);

                const trigger = document.getElementById('ipsqTriger');
                if (trigger) {
                    globalParams.started_ipqs_request = Date.now();
                    trigger.click();
                } else {
                    reject(new Error('#ipsqTriger not found'));
                }
            });
        } catch (error) {
            throw error;
        }
    }
}


export function mountedHomePage(vue, loader) {
    let isUnsubscribed = localStorage.getItem('unsubscribeSuccess');
    if (isUnsubscribed) {
        vue.$cookies.remove('email')
        vue.$cookies.set('shared_email', null)
    }
    window.addEventListener('load', (event) => { //works after mount completed
        const message = localStorage.getItem('unsubscribeSuccess');
        if (message) {
            localStorage.removeItem('unsubscribeSuccess');
            alert(message);
        }
    });
    if(loader){
        loader.hide()
    }
    const urlParams = new URLSearchParams(window.location.search);
    const hasEmailParam = urlParams.has('email');
    if (globalParams.email && globalParams.aid_verified === null && globalParams.aid === null && !hasEmailParam) {
        window.location.href = '/reapply?email=' + globalParams.email + (globalParams.click_id === null ? '' : '&click_id=' + globalParams.click_id);
    }
}

export function applyStylesAndAttributes(siteSettings) {
    const elementsSettings = siteSettings.elements;

    for (const id in elementsSettings) {
        const element = document.getElementById(id);
        if (!!element === false) {
            continue;
        }

        const attributes = elementsSettings[id];

        for (const key in attributes) {
            const attributeValue = attributes[key];

            if (!attributeValue && typeof attributeValue !== "number") {
                continue;
            }

            // Check if the property is a child element's class
            if (key.startsWith('CE_')) {
                const childElements = element.getElementsByClassName(key);

                outerLoop: for (const childElement of childElements) {
                    let showOnlyBgColor = false;
                    for (const cssProperty in attributeValue) {
                        if (cssProperty !== 'label') {
                            if (cssProperty === 'required' && attributeValue.required === false) {
                                continue outerLoop;
                            } else if (cssProperty === 'bg_switch' && attributeValue[cssProperty]) {
                                showOnlyBgColor = true;
                            } else if (cssProperty === 'fill') {
                                childElement.setAttribute('fill', attributeValue[cssProperty]);
                            } else if (cssProperty === 'src') {
                                childElement.src = attributeValue[cssProperty]
                            } else {
                                if (showOnlyBgColor && cssProperty === 'background-image') {
                                    showOnlyBgColor = false;
                                    continue; //skip background-image setting
                                }

                                childElement.style.setProperty(
                                    cssProperty,
                                    attributeValue[cssProperty]
                                );
                            }
                        }
                    }
                }
            }

            // Check if the property is a pseudo-class
            if (key.startsWith(":")) {
                // Apply styles to pseudo-class (hover, active, etc.)
                const pseudoStyles = attributes[key];
                for (const styleKey in pseudoStyles) {
                    const styleValue = pseudoStyles[styleKey];
                    if (styleValue.variable) {
                        // Assuming we're setting CSS variables on the element
                        element.style.setProperty(
                            styleValue.variable,
                            styleValue.value
                        );
                    }
                }
            }

            if (key === "src") {
                element.src = attributeValue;
            }

            if (key === "content") {
                element.textContent = attributeValue;
            }

            if (typeof attributeValue === "object" && attributeValue.hasOwnProperty("value")) {
                // If the value is an object with a 'value' key, use that value
                element.style[key] = attributeValue.value;
            } else {
                if (key === 'color') {
                    const paths = element.getElementsByTagName('path');
                    for (const path of paths) {
                        path.setAttribute('stroke', attributeValue);
                    }
                }
                element.style[key] = attributeValue;
            }
        }
    }

    const generalSettings = siteSettings.general;

    if (!!generalSettings === false) {
        return;
    }

    document.querySelector('link[rel=icon]').href = generalSettings.favicon
}

/**
 * Prepare subIds string
 * @param subIds
 * @returns {string}
 */
export function getSubIdsString(subIds) {
    let returnText = '';
    if (subIds !== null) {
        Object.entries(subIds).forEach(entry => {
            const [key, value] = entry;
            returnText += '&' + key + '=' + value;
        });
    }
    return returnText;
}
